<script>
import AIContentGeneratorTitleIdeasForm from '@app/components/AIContentGeneratorTitleIdeasForm.vue';
import AIContentGeneratorValuePropositionForm from '@app/components/AIContentGeneratorValuePropositionForm.vue';
import AIContentGeneratorOutlineForm from '@app/components/AIContentGeneratorOutlineForm.vue';
import AIContentGeneratorSalesPageForm from '@app/components/AIContentGeneratorSalesPageForm.vue';

export default {
  head: {
    title: 'Rédigez plus rapidement grâce à votre Copywriter IA',
  },
  components: {
    title_ideas: AIContentGeneratorTitleIdeasForm,
    value_proposition: AIContentGeneratorValuePropositionForm,
    outline: AIContentGeneratorOutlineForm,
    sales_page: AIContentGeneratorSalesPageForm,
  },
  computed: {
    type() {
      const typeFromRoute = this.$route.query.type;

      if (!this.$constants.AI_CONTENT_TYPES.includes(typeFromRoute)) {
        return 'title_ideas';
      }

      return typeFromRoute;
    },
  },
  created() {
    if (!this.$route.query.type) {
      this.$router.replace({ query: { type: this.type } });
    }
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4">
      <nav class="menu">
        <ul class="menu-list">
          <router-link
            :to="{query: {type: 'title_ideas'}}"
            tag="li"
            exact-active-class="is-active"
            custom
            v-slot="{ href, isExactActive, navigate }"
          >
            <a
              :href="href"
              :class="{'is-active': isExactActive}"
              @click="navigate"
              v-t="'ai_assistant.content_types.title_ideas'"
            />
          </router-link>
          <router-link
            :to="{query: {type: 'value_proposition'}}"
            tag="li"
            exact-active-class="is-active"
            custom
            v-slot="{ href, isExactActive, navigate }"
          >
            <a
              :href="href"
              :class="{'is-active': isExactActive}"
              @click="navigate"
              v-t="'ai_assistant.content_types.value_proposition'"
            />
          </router-link>
          <router-link
            :to="{query: {type: 'outline'}}"
            tag="li"
            exact-active-class="is-active"
            custom
            v-slot="{ href, isExactActive, navigate }"
          >
            <a
              :href="href"
              :class="{'is-active': isExactActive}"
              @click="navigate"
              v-t="'ai_assistant.content_types.outline'"
            />
          </router-link>
          <router-link
            :to="{query: {type: 'sales_page'}}"
            tag="li"
            exact-active-class="is-active"
            custom
            v-slot="{ href, isExactActive, navigate }"
          >
            <a
              :href="href"
              :class="{'is-active': isExactActive}"
              @click="navigate"
              v-t="'ai_assistant.content_types.sales_page'"
            />
          </router-link>
        </ul>
      </nav>
    </div>
    <div class="column is-8">
      <component :is="type" />
    </div>
  </div>
</template>
