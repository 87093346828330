var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('nav',{staticClass:"menu"},[_c('ul',{staticClass:"menu-list"},[_c('router-link',{attrs:{"to":{query: {type: 'title_ideas'}},"tag":"li","exact-active-class":"is-active","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isExactActive = ref.isExactActive;
var navigate = ref.navigate;
return [_c('a',{directives:[{name:"t",rawName:"v-t",value:('ai_assistant.content_types.title_ideas'),expression:"'ai_assistant.content_types.title_ideas'"}],class:{'is-active': isExactActive},attrs:{"href":href},on:{"click":navigate}})]}}])}),_c('router-link',{attrs:{"to":{query: {type: 'value_proposition'}},"tag":"li","exact-active-class":"is-active","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isExactActive = ref.isExactActive;
var navigate = ref.navigate;
return [_c('a',{directives:[{name:"t",rawName:"v-t",value:('ai_assistant.content_types.value_proposition'),expression:"'ai_assistant.content_types.value_proposition'"}],class:{'is-active': isExactActive},attrs:{"href":href},on:{"click":navigate}})]}}])}),_c('router-link',{attrs:{"to":{query: {type: 'outline'}},"tag":"li","exact-active-class":"is-active","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isExactActive = ref.isExactActive;
var navigate = ref.navigate;
return [_c('a',{directives:[{name:"t",rawName:"v-t",value:('ai_assistant.content_types.outline'),expression:"'ai_assistant.content_types.outline'"}],class:{'is-active': isExactActive},attrs:{"href":href},on:{"click":navigate}})]}}])}),_c('router-link',{attrs:{"to":{query: {type: 'sales_page'}},"tag":"li","exact-active-class":"is-active","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isExactActive = ref.isExactActive;
var navigate = ref.navigate;
return [_c('a',{directives:[{name:"t",rawName:"v-t",value:('ai_assistant.content_types.sales_page'),expression:"'ai_assistant.content_types.sales_page'"}],class:{'is-active': isExactActive},attrs:{"href":href},on:{"click":navigate}})]}}])})],1)])]),_c('div',{staticClass:"column is-8"},[_c(_vm.type,{tag:"component"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }